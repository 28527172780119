var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fix-height" },
    [
      _c("q-field", {
        ref: "customLicense",
        staticClass: "customLicense",
        attrs: {
          dense: _vm.dense,
          "stack-label": "",
          filled: "",
          color: "orange-custom",
          label: _vm.convertLabel,
          disable: _vm.disabled || !_vm.editable,
          rules: !_vm.required ? null : [(val) => !!val || ""],
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _vm.haveLabel
                  ? _c(
                      "div",
                      { staticClass: "row items-center all-pointer-events" },
                      [
                        _c(
                          "b",
                          [
                            !_vm.required
                              ? _c("i", {
                                  staticClass:
                                    "pe-7s-note labelfrontIcon searchAreaLabelIcon",
                                })
                              : _vm._e(),
                            _c("font", { staticClass: "formLabelTitle" }, [
                              _vm._v(_vm._s(_vm.convertLabel)),
                            ]),
                            _vm.required
                              ? _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons labelfrontIcon text-requiredColor",
                                  },
                                  [_vm._v("check")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "control",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.valueText) + " ")]
            },
            proxy: true,
          },
          {
            key: "append",
            fn: function () {
              return [
                !_vm.disabled && _vm.editable
                  ? _c("q-icon", {
                      staticClass: "cursor-pointer",
                      attrs: { name: "search" },
                      on: { click: _vm.search },
                    })
                  : _vm._e(),
                !_vm.disabled && _vm.editable
                  ? _c("q-icon", {
                      staticClass: "cursor-pointer",
                      attrs: { name: "close" },
                      on: { click: _vm.reset },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.valueText,
          callback: function ($$v) {
            _vm.valueText = $$v
          },
          expression: "valueText",
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }