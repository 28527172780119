var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-btn",
    {
      class: [_vm.btnclass ? _vm.btnclass : "", "custom-btn"],
      attrs: {
        label: "QR 코드",
        disable: _vm.disabled,
        loading: _vm.loading,
        outline: _vm.outline,
        size: _vm.size,
        icon: "qr_code",
        color: "black",
      },
    },
    [
      _c(
        "q-popup-proxy",
        [
          _c(
            "span",
            { attrs: { action: "#", id: "printJS-form" } },
            [
              _c("vue-qrcode", {
                staticClass: "canvas",
                attrs: {
                  value: _vm.serverName + _vm.mobileUrl,
                  options: { width: 180 },
                },
              }),
            ],
            1
          ),
          _vm.isPrint
            ? _c("q-btn", {
                attrs: { label: "", color: "grey", size: "xs", icon: "print" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.print.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._t("detail"),
        ],
        2
      ),
      _vm._t("tooltip"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }